export function timeFromDate(dateOne) {
  const date1 = new Date(dateOne);
  const date2 = new Date();
  const time = date2.getTime() - date1.getTime();
  const days = Math.floor(time / (1000 * 3600 * 24));
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 31);

  if (months > 0) {
    return `vor ${months} ${months === 1 ? 'Monat' : 'Monaten'}`;
  }

  if (weeks > 0) {
    return `vor ${weeks} ${weeks === 1 ? 'Woche' : 'Wochen'}`;
  }

  return `vor ${days} ${days === 1 ? 'Tag' : 'Tagen'}`;
}

export function WrapTextFromArray(text, replaceArray, wrapTag, styles, color) {
  var finalString = text;
  for (var i = replaceArray.length - 1; i >= 0; i--) {
    finalString = finalString.replace(
      RegExp(
        '(' +
          replaceArray[i].replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') +
          ')(?!w)',
        'g'
      ),
      `<${wrapTag} ${styles ? styles : ''}>${replaceArray[i]}</${wrapTag}>`
    );
  }

  return finalString;
}

export function CalcDistanceBtwTwoCord(lat1, lon1, lat2, lon2) {
  const R = 6371e3;
  const f1 = (lat1 * Math.PI) / 180;
  const f2 = (lat2 * Math.PI) / 180;
  const d1 = ((lat2 - lat1) * Math.PI) / 180;
  const d2 = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(d1 / 2) * Math.sin(d1 / 2) +
    Math.cos(f1) * Math.cos(f2) * Math.sin(d2 / 2) * Math.sin(d2 / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distanceInMetres = R * c;
  const distanceInKm = (distanceInMetres / 1000).toFixed(1);
  return distanceInKm;
}

export function SortOriginalList(originalList, lat, lng) {
  return originalList.sort((user1, user2) => {
    const { Breitengrad: Breitengrad1, Longengrad: Longengrad1 } = user1;
    const { Breitengrad: Breitengrad2, Longengrad: Longengrad2 } = user2;

    let aDist = CalcDistanceBtwTwoCord(lat, lng, Breitengrad1, Longengrad1);
    let bDist = CalcDistanceBtwTwoCord(lat, lng, Breitengrad2, Longengrad2);

    return aDist - bDist;
  });
}

export function FilterSeminar(seminarArray, showPastEvents) {
  const currentDate = new Date();
  if (showPastEvents) {
    return [...seminarArray].filter(
      (item) => new Date(item.date) < currentDate
    );
  } else {
    return [...seminarArray].filter(
      (item) => new Date(item.date) > currentDate
    );
  }
}
