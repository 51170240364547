import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import MenuItem from './MenuItem';
import PatientenLogin from './PatientenLogin';

const Menu = [
  {
    name: 'Neurodermitis',
    subItems: [
      { name: 'Verstehen', link: '/verstehen', imageName: 'verstehen_img_1' },
      { name: 'Symptome', link: '/symptome', imageName: 'symptome_img_1' },
      { name: 'Ursachen', link: '/ursachen', imageName: 'ursachen_img_1' },
      {
        name: 'Lernplattform',
        link: '/lernplattform',
        linkType: 'a',
        imageName: 'learnplattformCover',
        altTag: 'Junge Frau, die mit einem Tablet an einem Tisch sitzt',
      },
    ],
  },

  {
    name: 'Behandlung',
    subItems: [
      { name: 'Überblick', link: '/ueberblick', imageName: 'uberblick_img_' },
      {
        name: 'Äußerliche Therapien',
        link: '/aeusserliche-therapien',
        imageName: 'ausserliche-therapien_img_1',
      },
      {
        name: 'Innerliche Therapien',
        link: '/innerliche-therapien',
        imageName: 'newTherapienImage',
      },
      {
        name: 'Ergänzende Therapien',
        link: '/ergaenzende-therapien',
        imageName: 'ergaenzende-therapien_img_1',
      },
    ],
  },

  {
    name: 'Babys und Kinder',
    subItems: [
      {
        name: 'Behandlung',
        link: '/kinder-behandlung',
        imageName: 'behandlung_img_1',
      },
      {
        name: 'Neurodermitis bei Kindern von 0-5 Jahren',
        link: '/kinder-von-0-5-jahren',
        imageName: 'babys_img_1',
      },
      {
        name: 'Neurodermitis bei Kindern von 6-12 Jahren',
        link: '/kinder-von-6-12-jahren',
        imageName: 'schulkinder_img_1',
      },
      {
        name: 'Neurodermitis bei Jugendlichen von 13-17 Jahren',
        link: '/jugendliche-von-13-17-jahren',
        imageName: 'jugendliche_img_1',
      },
      {
        name: 'Tipps für den Alltag',
        link: '/tipps-fuer-den-alltag',
        imageName: 'tipps-im-alltag_img_1',
      },
    ],
  },

  { name: 'Blog', subItems: [] },

  {
    name: 'Service',
    subItems: [
      {
        name: 'Broschüren und Downloads',
        link: '/broschueren-und-downloads',
        imageName: 'broschuren_img_1',
      },
      {
        name: 'Veranstaltungen',
        link: '/veranstaltungen',
        imageName: 'veranstaltungen',
      },
      {
        name: 'Social Media',
        link: '/social-media',
        imageName: 'social-media_img_1',
        altTag: 'Eine junge Frau mit Handy',
      },
    ],
  },

  {
    name: 'Unterstützung finden',
    subItems: [
      {
        name: 'Selbsttest',
        link: '/selbsttest',
        linkType: 'a',
        imageName: 'Selbsttest_img_1',
        altTag: 'Junge Frau sitzt am LaptopSelbsttest',
      },
      {
        name: 'Ärztefinder',
        link: '/aerztefinder',
        imageName: 'kopfhaur_img_5',
        altTag: 'Eine Ärztin im Gespräch mit einer Patientin',
      },
      {
        name: 'Neurodermitis-Berater kontaktieren',
        link: '/neurodermitis-berater-kontaktieren',
        imageName: 'Berater_img_1',
        altTag:
          'Eine junge Frau blick verträumt in die Ferne, während sie ein Handy hält',
      },
      {
        name: 'Teledermatologie',
        link: '/teledermatologie',
        imageName: 'teledermatologie_img_1',
        altTag: 'Eine junge Frau mit Kaffeetasse und Handy',
      },
      {
        name: 'Anlaufstellen',
        link: '/anlaufstellen',
        imageName: 'anlaufstellen_img_1',
      },
    ],
  },
];

export function MobileMenu() {
  const [menuOpen, setMenuOpen] = useState(false);
  function toggleMenu() {
    setMenuOpen(!menuOpen);
    const html = document.querySelector('html');
    html.style.overflow = menuOpen ? 'auto' : 'hidden';
    html.style.height = '100%';
  }
  return (
    <nav>
      {!menuOpen && (
        <svg
          className="menu-icon-open"
          onClick={toggleMenu}
          xmlns="http://www.w3.org/2000/svg"
          width="35px"
          height="35px"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#633BB0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <line x1="2" y1="7" x2="22" y2="7"></line>
          <line x1="2" y1="12" x2="22" y2="12"></line>
          <line x1="2" y1="17" x2="22" y2="17"></line>
        </svg>
      )}
      {menuOpen && (
        <>
          <svg
            className="menu-icon-close"
            onClick={toggleMenu}
            xmlns="http://www.w3.org/2000/svg"
            width="35px"
            height="35px"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#633BB0"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
          <Accordion flush alwaysOpen className="mobile-menu">
            {Menu.map((menuItem, idx) => {
              return (
                <MenuItem
                  menuItemIdx={idx}
                  name={menuItem.name}
                  subItems={menuItem.subItems}
                  onItemClick={toggleMenu}
                  totalItems={Menu.length}
                />
              );
            })}
            <PatientenLogin />
          </Accordion>
        </>
      )}
    </nav>
  );
}

export function DesktopMenu() {
  return (
    <nav className="mega-menu-container">
      {Menu.map((menuItem, idx) => {
        return (
          <MenuItem
            menuItemIdx={idx}
            name={menuItem.name}
            subItems={menuItem.subItems}
            totalItems={Menu.length}
          />
        );
      })}
    </nav>
  );
}
