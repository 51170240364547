import React, { useContext, useState } from 'react';
import loginIcon from '../assets/icons/Login-Icon.svg';
import { MobileContext } from './Layout';
import popUpIconClose from '../assets/icons/popUpIconClose.svg';
import loginBulb from '../assets/images/loginBulb.png';
import Button from './Button';

const PatientenLogin = () => {
    const isMobile = useContext(MobileContext);

    const handleClick = () => {
        window.open('https://www.typ2-inflammation.de/mywayapp-patienten', '_blank').focus();
    };

    const mobileText = (
        <>
            <hr className="main-separator"></hr>
            <p
                onClick={handleClick}
                style={{ cursor: 'pointer' }}
                className="menu-link">
                Für Patienten
            </p>
        </>
    );

    const desktopText = (
        <div onClick={handleClick} className="patient-login-button-container">
            <img src={loginIcon} alt="" />
            <p className="quote">Für Patienten</p>
        </div>
    );

    return (
        <div className="patient-login">
            {isMobile ? mobileText : desktopText}
        </div>
    );
};

export default PatientenLogin;
