import React, { useEffect } from 'react';
import icon from '../assets/icons/back-to-top-arrow.svg';

export default function BackToTopButton() {
  useEffect(() => {
    window.addEventListener('scroll', calcScrollValue);

    return () => {
      window.removeEventListener('scroll', calcScrollValue);
    };
  }, []);

  return (
    <div id="back-to-top" onClick={backToTop}>
      <div className="inner">
        <img src={icon} alt="" />
        <p>Top</p>
      </div>
    </div>
  );
}

function calcScrollValue() {
  let button = document.getElementById('back-to-top');

  let pos = document.documentElement.scrollTop;
  let calcHeight =
    document.documentElement.scrollHeight -
    document.documentElement.clientHeight;
  let scrollPercentage = Math.round((pos * 100) / calcHeight);

  button.style.display = pos > getDocumentHeight() / 4 ? 'flex' : 'none';
  button.style.background = `conic-gradient(#FF6142 ${scrollPercentage}%, #633BB0 ${scrollPercentage}%)`;
}

function backToTop() {
  document.documentElement.scrollTop = 0;
}

function getDocumentHeight() {
  var body = document.body,
    html = document.documentElement;

  var height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );

  return height;
}
