import { Link } from 'gatsby';
import React from 'react';
import caret from '../assets/icons/caret-right.svg';

export default function Button({
    type,
    text,
    to,
    style,
    onClick,
    img,
    linkType,
    linkTarget = null
}) {
  return (
    <>
      {linkType === 'a' ? (
        <a href={to} target={linkTarget} className="havas-button button-anchor">
          <button onClick={onClick} className={type + '-button'}>
            {text}
            <img src={img ? img : caret} alt="" />
          </button>
        </a>
      ) : (
        <Link style={style} to={to} target={linkTarget} className="havas-button button-anchor">
          <button onClick={onClick} className={type + '-button'}>
            {text}
            <img src={img ? img : caret} alt="" />
          </button>
        </Link>
      )}
    </>
  );
}
